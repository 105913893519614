<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

var moment = require("moment");
moment.locale("fr");

import { Api } from "../../../helpers";
/**
 * Projects-overview component
 */
export default {
  page: {
    title: "Projects Overview",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      sectionsProgramme: [],
      listFav: false,
      listLike: false,
      moment: moment,
      likesList: [],
      commentList: [],
      favList: [],
      description: "",
      titre: "",
      dateAdd: "",
      duree: "",
      annee: "",
      linkLive: "",
      categorie: "",
      logo: "",
      tags: [],
      image: "",
      vue: "",
      title: "Details de la chaîne",
      items: [
        {
          text: "Channels",
          href: "/projects/channelDetails",
        },
        {
          text: "Détails",
          active: true,
        },
      ],
    };
  },
  methods: {
    showListFav() {
      this.listFav = true;
    },
    showListLikes() {
      this.listLike = true;
    },
    getDateAndTimeFromDate(date) {
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();

      const dateStr = `${year}/${month}/${day}`;

      return { date: dateStr };
    },
  },
  async mounted() {
    this.id = this.$route.params.id;

    const result = await Api.get(
      "/streamvod/rest/channel/{id}?id=" + this.$route.params.id
    );
    this.video = result.data.content;

    this.$refs.videoPlayer.src = this.video.channel_url;

    this.linkLive = this.video.channel_url;
    this.description = this.video.channel_description;
    this.titre = this.video.channel_name;
    this.logo = this.video.channel_logo.url;
    // this.image = this.video.image_live.url
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-8">
        <div class="card">
          <div class="card-body">
            <!-- <div class="d-flex">
                  <img src="@/assets/images/companies/img-1.png" alt class="avatar-sm me-4" />

                  <div class="flex-grow-1 overflow-hidden">
                    <h5 class="text-truncate font-size-15">Skote Dashboard UI</h5>
                    <p class="text-muted">Separate existence is a myth. For science, music, sport, etc.</p>
                  </div>
                </div> -->
            <div
              style="
                position: relative;
                padding-bottom: 56.25%;
                height: 0;
                overflow: hidden;
              "
              itemscope
              itemtype="https://schema.org/VideoObject"
            >
              <video
                ref="videoPlayer"
                src=""
                controls
                style="
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  left: 0px;
                  top: 0px;
                  overflow: hidden;
                "
                width="720"
                height="480"
              ></video>
            </div>

            <div
              class="c2play-border-primary"
              style="
                border-radius: 5px;
                padding-right: 10px;
                padding-left: 10px;
                margin-top: 15px;
                margin-bottom: 20px;
              "
            >
              <h2 class="mt-4">{{ titre }}</h2>
              <h5 style="font-style: italic">{{ description }}</h5>
            </div>
          </div>
        </div>
      </div>
      <!-- end col -->

      <div class="col-lg-4">
        <div class="card">
          <div class="card-body">
            <h4 class="mb-4">Logo de la chaine</h4>

            <div class="product-detai-imgs" style="">
              <img
                :src="logo"
                alt
                style="
                  object-fit: cover;
                  height: 200px;
                  width: 100%;
                  border-radius: 10px;
                  margin-bottom: 20px;
                "
              />
            </div>

            <h4 class="mb-4">Live par defaut</h4>

            <div class="product-detai-imgs" style="">
              <img
                :src="image"
                alt
                style="
                  object-fit: cover;
                  height: 200px;
                  width: 100%;
                  border-radius: 10px;
                  margin-bottom: 20px;
                "
              />
            </div>
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->

    <div class="row">
      <div class="col-lg-4"></div>

      <b-modal
        v-model="listLike"
        id="modal-ml"
        size="ml"
        title="Détails du programme"
        title-class="font-18"
        hide-footer
      >
        <div class="card">
          <div class="comment-block card">
            <div class="card-body">
              <h4 class="card-title mb-4">
                {{ $t("pages.overviewVideos.likes") }}
                {{ this.likesList.length }}
              </h4>

              <div id="overview-chart" class="apex-charts" dir="ltr">
                <table
                  class="table table-nowrap table-centered table-hover mb-0 align-middle"
                >
                  <tbody>
                    <tr v-for="(like, index) in likesList" :key="index">
                      <td style="width: 45px">
                        <div class="avatar-sm">
                          <span
                            class="avatar-title rounded-circle bg-soft bg-primary text-primary font-size-24"
                          >
                            <img
                              class="media-object rounded-circle avatar-xs"
                              alt
                              src="@/assets/logos/images/profile.png"
                            />
                          </span>
                        </div>
                      </td>
                      <td>
                        <h5 class="font-size-14 mb-1">
                          {{ like }}
                        </h5>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </b-modal>

      <b-modal
        v-model="listFav"
        id="modal-ml"
        size="ml"
        title="Détails du programme"
        title-class="font-18"
        hide-footer
      >
        <div class="card">
          <div class="comment-block card">
            <div class="card-body">
              <h4 class="card-title mb-4">
                {{ $t("pages.overviewVideos.fav") }} {{ favList.length }}
              </h4>
              <div class="table-responsive">
                <table
                  class="table table-nowrap table-centered table-hover mb-0 align-middle"
                >
                  <tbody>
                    <tr v-for="(fav, index) in favList" :key="index">
                      <td style="width: 45px">
                        <div class="avatar-sm">
                          <span
                            class="avatar-title rounded-circle bg-soft bg-primary text-primary font-size-24"
                          >
                            <img
                              class="media-object rounded-circle avatar-xs"
                              alt
                              src="@/assets/logos/images/profile.png"
                            />
                          </span>
                        </div>
                      </td>
                      <td>
                        <h5 class="font-size-14 mb-1">
                          {{ fav }}
                        </h5>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div
          v-for="section in sectionsProgramme"
          :key="section.id"
          class="card pt-4"
          style="padding-left: 5%; padding-right: 5%"
        >
          <div class="row">
            <h2 class="col-lg-11">{{ section.titre }}</h2>
            <div class="col-lg-1">
              <p class="card-title-desc">
                <b-dropdown
                  class="card-drop"
                  variant="white"
                  menu-class="dropdown-menu-end"
                  right
                  toggle-class="p-0"
                >
                  <template v-slot:button-content>
                    <i class="mdi mdi-dots-horizontal font-size-18"></i>
                  </template>
                  <!-- <b-dropdown-item :href="'/projects/overview/' + section.id"
                                        >{{ $t("pages.sections.actions.details") }}</b-dropdown-item
                                    > -->
                  <b-dropdown-item @click="selectOneSection(section.id)">{{
                    $t("pages.sections.actions.modifier")
                  }}</b-dropdown-item>
                  <b-dropdown-item @click="confirm(section.id)">{{
                    $t("pages.sections.actions.supprimer")
                  }}</b-dropdown-item>
                </b-dropdown>
              </p>
            </div>
          </div>

          <div class="display">
            <VideosCardVue
              v-for="video in section.videos.slice().reverse()"
              :key="video.id"
              :image="video.image.url"
              :title="video.title"
              :vues="video.nbVues"
              :likes="video.likedUsers.length"
              :time="video.duration"
              :years="video.years"
              :id="video.id"
              :initialTags="video.tags"
            ></VideosCardVue>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
